<template>
  <b-modal
    id="modal-styletype-edit"
    no-stacking
    @show="initializeModal"
  >
    <!--begin::header-->
    <template
      #modal-header
    >
      <h5 class="modal-title">
        {{ trans('templates.edit.styletypes.edit.title', { styletypename: formName }) }}
      </h5>
    </template>
    <!--begin::header-->

    <!--begin::body-->
    <template
      #default
    >
      <!--begin::Styletype-render-->
      <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
        <div class="symbol symbol-50 symbol-lg-200">
          <!--begin::Render-->
          <UploadImage
            :container-height="200"
            :container-width="200"
            db-collection="templates"
            :doc-id="templateId"
            :db-path="`styletypes.${ styletypeKey }.renders`"
            :default-image="styletypeRender"
            cloud-function="backendTemplatesStyletypeUpdateRender"
          ></UploadImage>
          <!--end::Render-->
        </div>
      </div>
      <!--end::Styletype-render-->

      <b-form
        class="form"
        novalidate
      >
        <!--begin::form-fields-->
        <div>
          <!--begin::form-name-->
          <b-form-group
            :label="trans('templates.edit.styletypes.add.form.fields.name.title')"
            label-for="template_name"
            class="font-size-h6 font-weight-bolder text-dark"
          >
            <!--begin::form-name-field-->
            <b-form-input
              id="styletype_name"
              name="styletype_name"
              v-model="$v.name.$model"
              type="text"
              class="form-control form-control-solid h-auto py-4"
              :state="validateState('name')"
              :placeholder="trans('templates.edit.styletypes.add.form.fields.name.placeholder')"
              aria-describedby="form-name-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback
              id="form-name-live-feedback"
            >
              <!--eslint-disable max-len -->
              <span v-if="$v.name.$errors[0] !== undefined && $v.name.$errors[0].$validator === 'alphaAndHyphenAndApostrophe'">
                {{ trans('templates.edit.styletypes.add.form.fields.name.errors.alpha') }}
              </span>
              <span v-else>
                {{ trans('templates.edit.styletypes.add.form.fields.name.errors.required') }}
              </span>
              <!--eslint-enable max-len -->
            </b-form-invalid-feedback>
            <!--end::form-name-field-->
          </b-form-group>
          <!--end::form-name-->

          <!--begin::form-description-->
          <b-form-group
            :label="trans('templates.edit.styletypes.add.form.fields.desc.title')"
            labe-for="template_desc"
            class="font-size-h6 font-weight-bolder text-dark"
          >
            <!--begin::form-desc-area-->
            <b-form-textarea
              id="template_desc"
              name="template_desc"
              v-model="$v.desc.$model"
              class="form-control form-control-solid"
              :state="validateState('desc')"
              :placeholder="trans('templates.edit.styletypes.add.form.fields.desc.placeholder')"
              aria-describedby="template_desc_live_feedback"
              rows="3"
              max-rows="5"
            ></b-form-textarea>
            <!--end::form-desc-area-->

            <b-form-invalid-feedback id="template_desc_live_feedback">
              {{ trans('templates.edit.styletypes.add.form.fields.desc.error') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <!--end::form-description-->

          <!--begin::form-position-->
          <b-form-group
            :label="trans('templates.edit.styletypes.add.form.fields.position.title')"
            label-for="styletype_position"
            class="font-size-h6 font-weight-bolder text-dark"
          >
            <!--begin::form-position-field-->
            <b-form-input
              id="styletype_position"
              name="styletype_position"
              v-model="$v.position.$model"
              type="text"
              class="form-control form-control-solid h-auto py-4"
              :state="validateState('position')"
              :placeholder="trans('templates.edit.styletypes.add.form.fields.position.placeholder')"
              aria-describedby="form-position-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback
              id="form-position-live-feedback"
            >
              <span>
                {{ trans('templates.edit.styletypes.add.form.fields.position.errors.alpha') }}
              </span>
            </b-form-invalid-feedback>
            <!--end::form-position-field-->
          </b-form-group>
          <!--end::form-position-->

          <!--begin::form-standard-->
          <b-form-group
            :label="trans('templates.edit.styletypes.edit.form.fields.standard.title')"
            label-for="styletype_standard"
            class="font-size-h6 font-weight-bolder text-dark"
          >
            <!--begin::form-standard-select-->
            <b-form-select
              id="styletype_standard"
              name="styletype_standard"
              v-model="$v.standard.$model"
              :options="standardSelect"
              :state="validateState('standard')"
              class="form-control form-control-solid h-auto py-4"
            ></b-form-select>
            <!--begin::form-standard-select-->
          </b-form-group>
          <!--end::form-standard-->
        </div>
        <!--end::form-fields-->
      </b-form>
    </template>
    <!--end::body-->

    <!--begin::footer-->
    <template
      #modal-footer="{ cancel }"
    >
      <b-btn
        variant="secondary"
        class="px-7 py-4"
        @click="cancel"
      >
        {{ capitalize(transChoice('global.cancel', 0)) }}
      </b-btn>
      <b-btn
        variant="primary"
        class="px-7 py-4"
        :class="submitButtonAnimation ? 'spinner spinner-light spinner-right pr-12' : ''"
        :disabled="submitButtonAnimation"
        @click="saveTemplate()"
      >
        <span v-if="submitButtonAnimation">
          {{ capitalize(trans('templates.edit.styletypes.edit.btn.loading')) }}
        </span>
        <span v-else>
          {{ capitalize(trans('templates.edit.styletypes.edit.btn.default')) }}
        </span>
      </b-btn>
    </template>
    <!--begin::footer-->
  </b-modal>
</template>

<script>
  import { ref, computed } from '@vue/composition-api'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'
  import * as objectPath from 'object-path'

  import useFormValidation from '@src/use/formValidation'

  import useVuelidate from '@vuelidate/core'
  import { required, minLength, helpers, numeric } from '@vuelidate/validators'

  export default {
    name: 'ModalTemplatesAdd',
    props: {
      templateId: {
        type: String,
        required: true
      },
      styletypeData: {
        type: Object,
        required: true
      },
      styletypeKey: {
        type: String,
        required: true
      }
    },
    components: {
      UploadImage: () => import('@src/components/forms/UploadImage')
    },
    setup (props, { root }) {
      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const formName = ref('')
      const formDesc = ref('')
      const formPosition = ref('')
      const formStandard = ref(false)
      const submitted = ref(false)
      const submitButtonAnimation = ref(false)

      const styletypeRender = computed(() => {
        let imageUrl = ''
        const styletypeRenders = objectPath.get(props, 'styletypeData.renders', {})
        const currentImageKey = !isNaN(parseInt(Object.keys(styletypeRenders).pop(), 10)) ? parseInt(Object.keys(styletypeRenders).pop(), 10) : false // eslint-disable-line max-len

        if (currentImageKey !== false) {
          const imgPath = objectPath.get(props.styletypeData, `renders.${ currentImageKey }.path`, null)
          const imgFile = objectPath.get(props.styletypeData, `renders.${ currentImageKey }.file`, null)

          if (imgPath && imgFile) {
            imageUrl = `${process.env.VUE_APP_IMAGE_CDN_URL}/${ imgPath }/${ imgFile }?fit=crop&w=100&h=100`
          }
        }

        return imageUrl !== '' ? imageUrl : '/media/img/missing.png'
      })

      const alphaAndHyphenAndApostrophe = helpers.regex(/^[0-9a-zÆØÅæøå'\-\s]*$/iu)

      const validationRules = {
        name: {
          required,
          alphaAndHyphenAndApostrophe,
          minLength: minLength(2)
        },
        desc: {
          required
        },
        position: {
          numeric
        },
        standard: {}
      }

      const $v = useVuelidate(
        validationRules,
        {
          name: formName,
          desc: formDesc,
          position: formPosition,
          standard: formStandard
        }
      )

      const { validateState } = useFormValidation($v, submitted)

      const saveTemplate = async () => {
        submitted.value = true
        submitButtonAnimation.value = true

        $v.value.$touch()
        if (Object.keys($v.value.$errors).length > 0) {
          submitButtonAnimation.value = false
          return // Show errors in form
        }

        const updateStyletype = fb.functions.httpsCallable('backendTemplatesStyletypeUpdate')
        await updateStyletype({
          id: props.templateId,
          styletypeKey: props.styletypeKey,
          data: {
            name: formName.value,
            desc: formDesc.value,
            position: formPosition.value,
            standard: formStandard.value
          }
        }).then((res) => {
          submitButtonAnimation.value = false

          const errors = objectPath.get(res, 'data.errors', false)
          if (errors !== false) {
            root.$bvToast.toast(trans('templates.edit.styletypes.add.toast.error.msg', { error: errors[Object.keys(errors)[0]] }), { // eslint-disable-line max-len
              title: trans('templates.edit.styletypes.add.toast.error.title'),
              variant: 'danger',
              solid: true,
              autoHideDelay: 5000
            })
          } else {
            root.$bvToast.toast(trans('templates.edit.styletypes.add.toast.success.msg'), {
              title: trans('templates.edit.styletypes.add.toast.success.title'),
              variant: 'success',
              solid: true,
              autoHideDelay: 5000
            })
            root.$bvModal.hide('modal-styletype-edit')
          }
        }).catch((err) => {
          submitButtonAnimation.value = false
          root.$bvToast.toast(trans('templates.edit.styletypes.add.toast.error.msg', { error: err }), {
            title: trans('templates.edit.styletypes.add.toast.error.title'),
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000
          })
          console.log('ERRORS:', err)
        })

        return true
      }

      const initializeModal = () => {
        const { styletypeData } = props
        formName.value = objectPath.get(styletypeData, 'name', '')
        formDesc.value = objectPath.get(styletypeData, 'desc', '')
        formPosition.value = objectPath.get(styletypeData, 'position', '')
        formStandard.value = objectPath.get(styletypeData, 'standard', false)

        submitted.value = false
        submitButtonAnimation.value = false
      }

      const standardSelect = ref([
        {
          value: false,
          text: 'Nei'
        },
        {
          value: true,
          text: 'Ja'
        }
      ])

      return {
        $v,
        trans,
        transChoice,
        capitalize,
        saveTemplate,
        validateState,
        initializeModal,
        submitButtonAnimation,
        formName,
        formDesc,
        formPosition,
        formStandard,
        styletypeRender,
        standardSelect
      }
    }
  }
</script>

<style lang="scss">

</style>
