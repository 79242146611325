<template>
  <b-modal
    id="modal-styletype-delete"
    no-stacking
    @show="initializeModal"
  >
    <!--begin::header-->
    <template
      #modal-header
    >
      <h5 class="modal-title">
        {{ trans('templates.edit.styletypes.delete.title') }}
      </h5>
    </template>
    <!--begin::header-->

    <!--begin::body-->
    <template
      #default
    >
      <!--begin::content-->
      <p class="">
        {{ trans('templates.edit.styletypes.delete.desc', { styletypename: styletypeName }) }}
      </p>
      <!--end::content-->
    </template>
    <!--end::body-->

    <!--begin::footer-->
    <template
      #modal-footer="{ cancel }"
    >
      <b-btn
        variant="secondary"
        class="px-7 py-4"
        @click="cancel"
      >
        {{ capitalize(transChoice('global.cancel', 0)) }}
      </b-btn>
      <b-btn
        variant="danger"
        class="px-7 py-4"
        :class="submitButtonAnimation ? 'spinner spinner-light spinner-right pr-12' : ''"
        :disabled="submitButtonAnimation"
        @click="deleteStyletype()"
      >
        <span v-if="submitButtonAnimation">
          {{ capitalize(trans('templates.edit.styletypes.delete.btn.loading')) }}
        </span>
        <span v-else>
          {{ capitalize(trans('templates.edit.styletypes.delete.btn.default')) }}
        </span>
      </b-btn>
    </template>
    <!--begin::footer-->
  </b-modal>
</template>

<script>
  import { ref, computed } from '@vue/composition-api'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'
  import * as objectPath from 'object-path'
  import { TASKS } from '@src/store/tasks'

  export default {
    name: 'ModalTemplatesAdd',
    props: {
      templateId: {
        type: String,
        required: true
      },
      styletypeData: {
        type: Object,
        required: true
      },
      styletypeKey: {
        type: String,
        required: true
      }
    },
    setup (props, { root }) {
      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const submitButtonAnimation = ref(false)
      const styletypeName = computed(() => objectPath.get(props, 'styletypeData.name', trans('templates.edit.styletypes.delete.missingName'))) // eslint-disable-line max-len

      const deleteStyletype = async () => {
        submitButtonAnimation.value = true

        const deleteFunc = fb.functions.httpsCallable('backendTemplatesStyletypeDelete')
        await deleteFunc({
          id: props.templateId,
          styletypeKey: props.styletypeKey,
          data: {
            deleted: true
          }
        }).then((res) => {
          submitButtonAnimation.value = false

          const errors = objectPath.get(res, 'data.errors', false)
          if (errors !== false) {
            root.$bvToast.toast(trans('templates.edit.styletypes.delete.toast.error.msg', { error: errors[Object.keys(errors)[0]] }), { // eslint-disable-line max-len
              title: trans('templates.edit.styletypes.delete.toast.error.title'),
              variant: 'danger',
              solid: true,
              autoHideDelay: 5000
            })
          } else {
            root.$bvToast.toast(trans('templates.edit.styletypes.delete.toast.success.msg'), {
              title: trans('templates.edit.styletypes.delete.toast.success.title'),
              variant: 'success',
              solid: true,
              autoHideDelay: 5000
            })
            // Update the "oppgaver"-count
            root.$store.dispatch(TASKS.ACTIONS.FETCH_TASKS, { status: 0, limit: false }) // eslint-disable-line object-property-newline, max-len

            root.$bvModal.hide('modal-styletype-delete')
          }
        }).catch((err) => {
          submitButtonAnimation.value = false
          root.$bvToast.toast(trans('templates.edit.styletypes.delete.toast.error.msg', { error: err }), {
            title: trans('templates.edit.styletypes.add.toast.error.title'),
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000
          })
          console.log('ERRORS:', err)
        })

        return true
      }

      const initializeModal = () => {
        submitButtonAnimation.value = false
      }

      return {
        trans,
        transChoice,
        capitalize,
        initializeModal,
        deleteStyletype,
        styletypeName,
        submitButtonAnimation
      }
    }
  }
</script>

<style lang="scss">

</style>
