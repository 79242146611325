<template>
  <b-card
    class="card-custom gutter-b"
    no-body
  >
    <!--begin::card-header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <b-card-title>
        <h2 class="card-label">
          {{ trans('templates.edit.styletypes.list.title') }}
          <span class="d-block text-muted pt-2 font-size-sm">
            {{ trans('templates.edit.styletypes.list.desc') }}
          </span>
        </h2>
      </b-card-title>
      <!--begin::card-toolbar-->
      <div class="card-toolbar">
        <b-btn
          ref="sync_btn"
          variant="light-primary"
          class="py-3 px-4"
          size="md"
          @click="$bvModal.show('modal-styletype-add')"
        >
          <span class="">{{ trans('templates.edit.styletypes.list.btn') }}</span>
        </b-btn>
      </div>
      <!--end::card-toolbar-->
    </div>
    <!--end::card-header-->
    <!--begin::card-body-list-->
    <div v-if="styletypesExist" class="card-body" style="min-height: 80px;">
      <b-table
        id="styletypes-table"
        primary-key="styletypeId"
        :fields="fields"
        :items="items"
        responsive="md"
      >
        <!--begin::styletype-image-->
        <template
          v-if="tableProps.loading"
          v-slot:cell(image)
          class="styletype-image"
        >
          <div class="symbol symbol-50 symbol-sm flex-shrink-0">
            <div class="symbol-label">
              <div class="list-item loading rounded"></div>
            </div>
          </div>
        </template>

        <template
          v-else
          v-slot:cell(image)="data"
          class="styletype-image"
        >
          <div class="symbol symbol-60 symbol-sm flex-shrink-0 bg-light">
            <img :src="data.item.image" alt="Styletypeimage" />
          </div>
        </template>
        <!--end::styletype-image-->

        <!--begin::standard-->
        <template
          v-if="tableProps.loading"
          v-slot:cell(standard)
          class="styletype-standard"
        >
          <div class="list-item loading rounded"></div>
        </template>

        <template
          v-else
          v-slot:cell(standard)="data"
          class="styletype-standard"
        >
          <span
          class="label label-rounded label-inline mr-4 label-xl"
          :class="data.item.standard ? 'label-success' : 'label-gray'"
          >
          {{ capitalize(data.item.standard ? transChoice('global.yes', 0) : transChoice('global.no', 0)) }}
          </span>
        </template>
        <!--end::standard-->

        <!--begin::created-->
        <template
          v-if="tableProps.loading"
          v-slot:cell(created)
          class="styletype-created"
        >
          <div class="list-item loading rounded"></div>
        </template>

        <template
          v-else
          v-slot:cell(created)="data"
          class="styletype-created"
        >
          {{ momentFullDate(data.item.created) }} @ {{ momentHoursMinutesSeconds(data.item.created) }}
        </template>
        <!--end::created-->

        <!--begin::actions-->
        <template
          v-if="tableProps.loading"
          v-slot:cell(actions)
          class="styletype-actions"
        >
          <div class="list-item loading rounded"></div>
        </template>

        <template
          v-else
          v-slot:cell(actions)="data"
          class="styletype-actions"
        >
          <b-button
            :ref="'styletype-view-' + data.item.id"
            variant="light-primary"
            size="sm"
            class="btn-icon btn-icon-primary"
            @click="openModal(data.item._key, 'edit')"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg :src="'/media/svg/icons/Design/Edit.svg'" />
            </span>
          </b-button>

          <b-button
            :ref="'styletype-view-' + data.item.id"
            variant="light-danger"
            size="sm"
            class="btn-icon btn-icon-danger ml-2"
            @click="openModal(data.item._key, 'delete')"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg :src="'/media/svg/icons/Home/Trash.svg'" />
            </span>
          </b-button>
        </template>
        <!--end::actions-->
      </b-table>
    </div>
    <!--begin::card-body-list-->
    <!--begin::card-body-empty-->
    <div v-else class="card-body">
      <b-alert
        variant="light-warning"
        class="alert-custom"
        show
      >
        <div class="alert-icon">
          <i class="fal fa-exclamation-triangle"></i>
        </div>
        <div class="alert-text">
          {{ trans('templates.edit.styletypes.list.empty') }}
        </div>
      </b-alert>
    </div>
    <!--end::card-body-empty-->
    <!--begin::Modals-->
    <ModalAddStyletype :template-id="templateId"></ModalAddStyletype>
    <ModalEditStyletype
      :template-id="templateId"
      :styletype-key="styletypeKey"
      :styletype-data="styletypeData"
    ></ModalEditStyletype>
    <ModalDeleteStyletype
      :template-id="templateId"
      :styletype-key="styletypeKey"
      :styletype-data="styletypeData"
    ></ModalDeleteStyletype>
    <!--end::Modals-->
  </b-card>
</template>

<script>
  import { SETTINGS } from '@src/store/settings'
  import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

  import { trans, transChoice } from '@src/core/helpers/translate'
  import { computed, onBeforeMount, onMounted, ref } from '@vue/composition-api'
  import * as objectPath from 'object-path'
  import { capitalize } from '@src/core/helpers/textUtils'
  import useDateTime from '@src/use/datetime'

  import ModalAddStyletype from '@src/components/modals/styletypes/Add'
  import ModalEditStyletype from '@src/components/modals/styletypes/Edit'
  import ModalDeleteStyletype from '@src/components/modals/styletypes/Delete'

  export default {
    name: 'TemplatesEditStyletypes',
    components: {
      ModalAddStyletype,
      ModalEditStyletype,
      ModalDeleteStyletype
    },
    setup (props, { root }) {
      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const styletypesExist = ref(true)
      const templateId = ref(root.$route.params.templateId)
      const styletypeKey = ref('')
      const styletypeData = ref({})
      const styletypes = ref({})
      const subMenuData = computed(() => root.$store.getters[SETTINGS.GETTERS.GET_SUBMENU_DATA])
      const subMenuName = computed(() => root.$store.getters[SETTINGS.GETTERS.GET_ACTIVE_SUBMENU])

      const templateName = ref('')

      const newSubMenuData = JSON.parse(JSON.stringify(subMenuData.value))
      objectPath.set(newSubMenuData, 'template.id', templateId.value)

      const { momentFullDate, momentHoursMinutesSeconds } = useDateTime()

      onBeforeMount(() => {
        if (subMenuName.value !== 'SubHeaderTemplatesEdit') {
          objectPath.del(newSubMenuData, 'template.name') // Reset the templatemenu
        }
        root.$store.dispatch(SETTINGS.ACTIONS.SET_ACTIVE_SUBMENU, 'TemplatesEdit')
        root.$store.dispatch(SETTINGS.ACTIONS.SET_SUBMENU_DATA, newSubMenuData)
      })

      const breadCrumbTitle = computed(() => {
        if (templateName.value === '') return capitalize(transChoice('global.loading', 2))
        return templateName.value
      })

      const setBreadCrumbs = () => {
        if (
          !objectPath.get(subMenuData, 'value.template.name', false) ||
          (templateName.value !== '' && objectPath.get(subMenuData, 'value.template.name', '') !== templateName.value) // eslint-disable-line no-extra-parens, max-len
        ) {
          newSubMenuData.template.name = templateName.value
          root.$store.dispatch(SETTINGS.ACTIONS.SET_SUBMENU_DATA, newSubMenuData)
          root.$store.dispatch(SET_BREADCRUMB, [
            {
              title: trans('nav.aside.templates.title'),
              route: '/templates'
            },
            {
              title: trans('nav.aside.templates.all'),
              route: '/templates'
            },
            {
              title: capitalize(transChoice('global.edit', 1))
            },
            {
              title: breadCrumbTitle.value
            }
          ])
        }
      }

      const tableProps = ref({
        limit: 10,
        loading: true,
        orderBy: 'createdOn',
        sortOrder: 'desc',
        lastVisibleDoc: true,
        showMoreBtn: true
      })

      const tableItems = ref(new Array(tableProps.value.limit).fill({}))

      const styletypeRender = (styletype) => {
        let imageUrl = ''
        const styletypeRenders = objectPath.get(styletype, 'renders', {})
        const lastImageKey = !isNaN(parseInt(Object.keys(styletypeRenders).pop(), 10)) ? parseInt(Object.keys(styletypeRenders).pop(), 10) : false // eslint-disable-line max-len

        if (lastImageKey !== false) {
          const imgPath = objectPath.get(styletype, `renders.${ lastImageKey }.path`, null)
          const imgFile = objectPath.get(styletype, `renders.${ lastImageKey }.file`, null)

          if (imgPath && imgFile) {
            imageUrl = `${process.env.VUE_APP_IMAGE_CDN_URL}/${ imgPath }/${ imgFile }?fit=crop&w=60&h=60`
          }
        }

        return imageUrl !== '' ? imageUrl : '/media/img/missing.png'
      }


      const items = computed(() => tableItems.value.map((styletype) => {
        if (Object.keys(styletype).length === 0) return {} // Styletype.length = 0 when preloading

        return {
          _key: objectPath.get(styletype, '_key', null),
          id: objectPath.get(styletype, 'id', null),
          image: styletypeRender(styletype),
          name: objectPath.get(styletype, 'name', trans('templates.edit.info.missingName')),
          position: objectPath.get(styletype, 'position', ''),
          standard: objectPath.get(styletype, 'standard', false),
          created: objectPath.get(styletype, 'createdOn', {})
        }
      }))

      const fields = computed(() => [
        {
          key: 'image',
          label: capitalize(transChoice('global.image', 0)),
          sortable: false,
          tdClass: 'styletype-image align-middle',
          thClass: 'styletype-image-head'
        },
        {
          key: 'name',
          label: capitalize(transChoice('global.name', 0)),
          sortable: false,
          tdClass: 'styletype-name align-middle',
          thClass: 'styletype-name-head'
        },
        {
          key: 'position',
          label: capitalize(transChoice('global.order', 0)),
          sortable: false,
          tdClass: 'styletype-position align-middle',
          thClass: 'styletype-position-head'
        },
        {
          key: 'standard',
          label: capitalize(transChoice('global.standard', 0)),
          sortable: false,
          tdClass: 'styletype-standard align-middle',
          thClass: 'styletype-standard-head'
        },
        {
          key: 'created',
          label: capitalize(transChoice('global.created', 1)),
          sortable: true,
          tdClass: 'styletype-created align-middle',
          thClass: 'styletype-created-head'
        },
        {
          key: 'actions',
          label: capitalize(transChoice('global.action', 2)),
          sortable: false,
          tdClass: 'styletype-actions align-middle',
          thClass: 'styletype-actions-head'
        }
      ])

      const initTemplateData = async () => {
        const getTemplate = fb.functions.httpsCallable('backendTemplatesGetTemplateById')

        return await getTemplate({
          id: templateId.value
        }).then((res) => {
          if (tableProps.value.loading) tableItems.value = []
          const styletypesData = objectPath.get(res, 'data.result.template.styletypes', {})

          // eslint-disable-next-line guard-for-in
          for (const stKey in styletypesData) {
            const styletype = styletypesData[stKey]
            styletype._key = stKey
            if (!objectPath.get(styletype, 'deleted', false)) {
              styletypes.value[stKey] = styletype
              tableItems.value.push(styletype)
            }
          }

          styletypesExist.value = tableItems.value.length > 0
          tableProps.value.loading = false
        }).catch((err) => {
          console.log('An error occurred while fetching the template:', err)
        })
      }

      initTemplateData()

      onMounted(() => {
        setBreadCrumbs()

        root.$on('bv::modal::hide', (bvEvent, modalId) => {
          if (
            modalId === 'modal-styletype-add' ||
            modalId === 'modal-styletype-edit' ||
            modalId === 'modal-styletype-delete'
          ) {
            tableItems.value = new Array(tableProps.value.limit).fill({})
            tableProps.value.loading = true
            initTemplateData()
          }
        })
      })

      const openModal = async (stKey, modal) => {
        styletypeData.value = styletypes.value[stKey]
        styletypeKey.value = stKey
        await new Promise((resolve) => setTimeout(resolve, 200)) // Populate the styletypeData
        root.$bvModal.show(`modal-styletype-${ modal }`)
      }

      return {
        trans,
        transChoice,
        fields,
        items,
        capitalize,
        templateId,
        tableProps,
        styletypeKey,
        styletypeData,
        styletypesExist,
        openModal,
        momentFullDate,
        momentHoursMinutesSeconds
      }
    }
  }
</script>

<style lang="scss">

</style>
