<template>
  <b-modal
    id="modal-styletype-add"
    no-stacking
    @show="initializeModal"
  >
    <!--begin::header-->
    <template
      #modal-header
    >
      <h5 class="modal-title">
        {{ trans('templates.edit.styletypes.add.title') }}
      </h5>
    </template>
    <!--begin::header-->

    <!--begin::body-->
    <template
      #default
    >
      <b-form
        class="form"
        novalidate
      >
        <!--begin::form-fields-->
        <div>
          <!--begin::form-name-->
          <b-form-group
            :label="trans('templates.edit.styletypes.add.form.fields.name.title')"
            label-for="template_name"
            class="font-size-h6 font-weight-bolder text-dark"
          >
            <!--begin::form-name-field-->
            <b-form-input
              id="styletype_name"
              name="styletype_name"
              v-model="$v.name.$model"
              type="text"
              class="form-control form-control-solid h-auto py-4"
              :state="validateState('name')"
              :placeholder="trans('templates.edit.styletypes.add.form.fields.name.placeholder')"
              aria-describedby="form-name-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback
              id="form-name-live-feedback"
            >
              <!--eslint-disable max-len -->
              <span v-if="$v.name.$errors[0] !== undefined && $v.name.$errors[0].$validator === 'alphaAndHyphenAndApostrophe'">
                {{ trans('templates.edit.styletypes.add.form.fields.name.errors.alpha') }}
              </span>
              <span v-else>
                {{ trans('templates.edit.styletypes.add.form.fields.name.errors.required') }}
              </span>
              <!--eslint-enable max-len -->
            </b-form-invalid-feedback>
            <!--end::form-name-field-->
          </b-form-group>
          <!--end::form-name-->

          <!--begin::form-description-->
          <b-form-group
            :label="trans('templates.edit.styletypes.add.form.fields.desc.title')"
            labe-for="template_desc"
            class="font-size-h6 font-weight-bolder text-dark"
          >
            <!--begin::form-desc-area-->
            <b-form-textarea
              id="template_desc"
              name="template_desc"
              v-model="$v.desc.$model"
              class="form-control form-control-solid"
              :state="validateState('desc')"
              :placeholder="trans('templates.edit.styletypes.add.form.fields.desc.placeholder')"
              aria-describedby="template_desc_live_feedback"
              rows="3"
              max-rows="5"
            ></b-form-textarea>
            <!--end::form-desc-area-->

            <b-form-invalid-feedback id="template_desc_live_feedback">
              {{ trans('templates.edit.styletypes.add.form.fields.desc.error') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <!--end::form-description-->

          <!--begin::form-position-->
          <b-form-group
            :label="trans('templates.edit.styletypes.add.form.fields.position.title')"
            label-for="styletype_position"
            class="font-size-h6 font-weight-bolder text-dark"
          >
            <!--begin::form-position-field-->
            <b-form-input
              id="styletype_position"
              name="styletype_position"
              v-model="$v.position.$model"
              type="text"
              class="form-control form-control-solid h-auto py-4"
              :state="validateState('position')"
              :placeholder="trans('templates.edit.styletypes.add.form.fields.position.placeholder')"
              aria-describedby="form-position-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback
              id="form-position-live-feedback"
            >
              <span>
                {{ trans('templates.edit.styletypes.add.form.fields.position.errors.alpha') }}
              </span>
            </b-form-invalid-feedback>
            <!--end::form-position-field-->
          </b-form-group>
          <!--end::form-position-->
        </div>
        <!--end::form-fields-->
      </b-form>
    </template>
    <!--end::body-->

    <!--begin::footer-->
    <template
      #modal-footer="{ cancel }"
    >
      <b-btn
        variant="secondary"
        class="px-7 py-4"
        @click="cancel"
      >
        {{ capitalize(transChoice('global.cancel', 0)) }}
      </b-btn>
      <b-btn
        variant="primary"
        class="px-7 py-4"
        :class="submitButtonAnimation ? 'spinner spinner-light spinner-right pr-12' : ''"
        :disabled="submitButtonAnimation"
        @click="saveTemplate()"
      >
        <span v-if="submitButtonAnimation">
          {{ capitalize(trans('templates.edit.styletypes.add.btn.loading')) }}
        </span>
        <span v-else>
          {{ capitalize(trans('templates.edit.styletypes.add.btn.default')) }}
        </span>
      </b-btn>
    </template>
    <!--begin::footer-->
  </b-modal>
</template>

<script>
  import { ref } from '@vue/composition-api'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'

  import useFormValidation from '@src/use/formValidation'

  import useVuelidate from '@vuelidate/core'
  import { required, minLength, helpers, numeric } from '@vuelidate/validators'
  import { TASKS } from '@src/store/tasks'
  import * as objectPath from 'object-path'

  export default {
    name: 'ModalTemplatesAdd',
    props: {
      templateId: {
        type: String,
        required: true
      }
    },
    setup (props, { root }) {
      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const formName = ref('')
      const formDesc = ref('')
      const formPosition = ref('')
      const submitted = ref(false)
      const submitButtonAnimation = ref(false)

      const alphaAndHyphenAndApostrophe = helpers.regex(/^[0-9a-zÆØÅæøå'\-\s]*$/iu)

      const validationRules = {
        name: {
          required,
          alphaAndHyphenAndApostrophe,
          minLength: minLength(2)
        },
        desc: {
          required
        },
        position: {
          numeric
        }
      }

      const $v = useVuelidate(
        validationRules,
        {
          name: formName,
          desc: formDesc,
          position: formPosition
        }
      )

      const { validateState } = useFormValidation($v, submitted)

      const saveTemplate = async () => {
        submitted.value = true
        submitButtonAnimation.value = true

        $v.value.$touch()
        if (Object.keys($v.value.$errors).length > 0) {
          submitButtonAnimation.value = false
          return // Show errors in form
        }

        const createTemplate = fb.functions.httpsCallable('backendTemplatesStyletypeAdd')
        await createTemplate({
          id: props.templateId,
          data: {
            name: formName.value,
            desc: formDesc.value,
            position: formPosition.value
          }
        }).then((res) => {
          submitButtonAnimation.value = false

          const errors = objectPath.get(res, 'data.errors', false)
          if (errors !== false) {
            root.$bvToast.toast(trans('templates.edit.styletypes.add.toast.error.msg', { error: errors[Object.keys(errors)[0]] }), { // eslint-disable-line max-len
              title: trans('templates.edit.styletypes.add.toast.error.title'),
              variant: 'danger',
              solid: true,
              autoHideDelay: 5000
            })
          } else {
            root.$bvToast.toast(trans('templates.edit.styletypes.add.toast.success.msg'), {
              title: trans('templates.edit.styletypes.add.toast.success.title'),
              variant: 'success',
              solid: true,
              autoHideDelay: 5000
            })
            // Update the "oppgaver"-count
            root.$store.dispatch(TASKS.ACTIONS.FETCH_TASKS, { status: 0, limit: false }) // eslint-disable-line object-property-newline, max-len

            root.$bvModal.hide('modal-styletype-add')
          }
        }).catch((err) => {
          submitButtonAnimation.value = false
          root.$bvToast.toast(trans('templates.edit.styletypes.add.toast.error.msg', { error: err }), {
            title: trans('templates.edit.styletypes.add.toast.error.title'),
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000
          })
          console.log('ERRORS:', err)
        })

        return true
      }

      const initializeModal = () => {
        formName.value = ''
        formDesc.value = ''
        formPosition.value = ''
        submitted.value = false
        submitButtonAnimation.value = false
      }

      return {
        $v,
        trans,
        transChoice,
        capitalize,
        saveTemplate,
        validateState,
        initializeModal,
        submitButtonAnimation
      }
    }
  }
</script>

<style lang="scss">

</style>
